<template>
  <div class="card">
    <!-- {{block}} -->
    <div class="row">
      <div class="col-sm-6">
        <v-text-field v-model="block.title" label="Заголовок блока вопросов:" dense hide-details class="mb-7" @input="debouncedChanged" />
        <v-select item-value="id" item-text="name" v-model="block.processor" :items="processors"
          label="Метод расчета"  dense hide-details class="mb-4" @change="onChange"
        />
        <v-btn class="mr-3 _w-100" color="primary" @click="showIntervalsDialog=true"> Редактировать интервалы </v-btn>
        <v-switch v-model="block.is_random_ordered" label="Случайный порядок" hide-details inset :ripple="false" class="ml-1" @change="onChange" />
        <v-switch v-model="block.is_answer_random_ordered" label="Случайный порядок ответов" hide-details inset :ripple="false" class="ml-1 mb-3" @change="onChange" />
      </div>
      <div class="col-sm-6">
        <v-textarea label="Описание" v-model="block.description" no-resize hide-details dense rows="6" class="mt-0 pt-0" @input="debouncedChanged"/>
      </div>
    </div>
    <div class="items-list-wrapper">
      <transition-group class="items-list w-100" name="list-complete" tag="div">
        <div v-if="!block.questions || !block.questions.length" class="mb-4" :key="-1">Добавьте вопросы</div>
        <div v-else class="mb-4" :key="-3">Вопросы:</div>
        <div class="row row-complete-item item-question" v-for="(question, ind) in block.questions" :key="question.id">
          <div class="col col-test-name d-flex">
            <div class="mr-2 mt-4 text-gray">{{ ind+1 }}.</div>
            <div class="question-text mt-4 text-container-limit" v-html="getQuestionText(question)"> </div>
            <div class="ml-3 item-question-type-hint">[ {{getQuestionName(question.type)}} ]</div>

          </div>
          <div class="col col-buttons-2 pl-0">
            <v-text-field label="Порядок" outlined dense v-model="question.order" hide-details @input="debouncedQuestionChanged(question)" />
          </div>
          <div class="col col-buttons-2 text-right">
            <v-btn icon color="primary" @click="editItem(question)" :disabled="!$store.getters['auth/isAllowed']('change_question')">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <!-- <v-btn icon color="primary"><v-icon>mdi-filter-outline</v-icon></v-btn> -->
            <v-btn icon color="error" @click="askDelete(question)" :loading="loading.del===question.id" :disabled="!$store.getters['auth/isAllowed']('delete_question')">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="row row-complete-item" :key="-2">
          <v-menu offset-y transition="scale-transition" :disabled="!$store.getters['auth/isAllowed']('add_question')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="add-button" fab dark small color="primary" v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
            </template>
            <v-list>
              <v-list-item link v-for="(item, index) in Q_TYPES_NAMES" :key="index">
                <v-list-item-title @click="addItem(item.type)">{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </transition-group>
    </div>
    <!-- <div class="items-list-wrapper">
      <div class="mb-4" :key="-1">Условия отображения блока:</div>
    </div> -->
    <AskDialog v-model="showAskDeleteDialog" @ok="delQuestion(curQuestion)" />
    <QuestionDialog v-model="showEditQuestionDialog" v-if="block.questions && curQuestion" :question="curQuestion" />
    <EditIntervalsDialog v-model="showIntervalsDialog" :block="block"/>
  </div>
</template>

<script>
/* eslint-disable */
import { debounce } from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import { getQuestionName, Q_TYPES_NAMES } from '@/components/testEditor/QuestionTypes.js';
import AskDialog from "@/components/ui/AskDialog"
import QuestionDialog from '@/components/testEditor/questionDialog/QuestionDialog.vue';
import EditIntervalsDialog from '@/components/testEditor/EditIntervalsDialog.vue';

export default {
  components: {
    AskDialog, QuestionDialog, EditIntervalsDialog
  },
  props: {
    block: Object,
  },
  data() {
    return {
      Q_TYPES_NAMES,
      showAskDeleteDialog: false,
      showEditQuestionDialog: false,
      showIntervalsDialog: false,
      curQuestion: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'testEditor/loading',
      processors: 'testEditor/processors',
    }),
  },
  methods: {
    ...mapActions({
      saveBlock: 'testEditor/saveBlock',
      addQuestion: 'testEditor/addQuestion',
      loadQuestion: 'testEditor/loadQuestion',
      delQuestion: 'testEditor/delQuestion',
      saveQuestion: 'testEditor/saveQuestion',
    }),
    getQuestionName,
    getQuestionText(question) {
      return /*question.text.length > 510 ? question.text.substring(0, 510)+'...' : */ question.text
    },
    async addItem(type) {
      const questionType = Q_TYPES_NAMES.find(el => el.type == type)
      const newTemplate = questionType ? questionType.newTemplate : 'Тип не найден'
      await this.addQuestion({
        block: this.block,
        type,
        newTemplate,
      })
    },
    askDelete(item) {
      this.showAskDeleteDialog = true
      this.curQuestion = item
    },
    async editItem(item) {
      // this.curQuestion = item
      this.curQuestion = await this.loadQuestion(item)
      this.showEditQuestionDialog = true
    },
    onChange() {
      this.saveBlock(this.block)
    },
    debouncedChanged: debounce(function (val) {
      this.onChange();
    }, 500),
    debouncedQuestionChanged: debounce(function (question) {
      this.saveQuestion(question);
    }, 500),
  }

}
</script>

<style>
.text-container-limit {
  max-height: 170px; /* Ограничение высоты */
  overflow: hidden; /* Скрытие переполненного текста */
  position: relative; /* Относительное позиционирование для наложения градиента */
}
.text-container-limit::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, #F9FBFC 200px);
  pointer-events: none; /* Отключение взаимодействия с элементом */
  z-index: 1;
}

</style>
