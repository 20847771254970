<template>
  <v-dialog v-model="dialogEditShow" max-width="1100px">
    <v-card class="pa-8 test-editor">
      <!-- {{ instrumentSet }} -->
      <div class="d-flex align-center mb-6">
        <h2 class="">Создание сборки</h2>
        <v-btn class="button-close ma-4" icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <v-text-field v-model="instrumentSet.name" label="Заголовок сборки тестирования:" dense hide-details class="mb-7" @input="onChanged" />
          <v-select item-value="id" v-model="instrumentSet.status" :items="statuses" label="Статус" dense hide-details class="mb-6" @change="onChanged" />
          <v-textarea v-if="instrumentSet" label="Сообщение перед началом тестирования" v-model="instrumentSet.description" no-resize :rows="4" class="mb-1" @input="onChanged" />

          <div class="items-list-wrapper">
            <transition-group class="items-list w-100" name="list-complete" tag="div">
              <div v-if="!instrumentSet.instruments || !instrumentSet.instruments.length" class="_mb-2" :key="-1">
                Добавьте опросники</div>
              <div class="item-question-hint" :key="-11">
                Для добавления инструмента в сборку нажимайте <v-icon size="10px" color="primary">mdi-arrow-left</v-icon> в списке доступных инструментов
              </div>
              <div v-if="instrumentSet.instruments && instrumentSet.instruments.length" class="mb-4" :key="-3">
                Инструменты, включенные в сборку:
              </div>
              <div class="row row-complete-item item-question" v-for="(instrument, ind) in instrumentSet.instruments" :key="instrument.instrument_id">
                <div class="col-9 d-flex align-center">
                  <div class="mr-2">{{ ind + 1 }}.</div>
                  <div> {{ getInstrumentName(instrument.instrument_id) }} </div>
                </div>
                <div class="col-3 pl-3 d-flex justify-end">
                  <v-btn icon color="error" @click="deleteFromSet(ind)">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
        <div :class="{ 'col-sm-6 _align-lists': instrumentSet, 'col-12': !instrumentSet }">
          <v-text-field v-model="instrumentSet.code" label="Код сборки:" dense class="mb-2" @input="onChanged" :error-messages="codeErrors" />
          <v-text-field v-model="instrumentSet.test_results_title" label="Заголовок описаний для отчета:" dense class="mb-2" @input="onChanged" />
          <v-textarea v-if="instrumentSet" label="Описание для отчета" v-model="instrumentSet.test_results_description" class="mb-1" :rows="4" no-resize @input="onChanged" />
          <div class="items-list-wrapper">
            <transition-group class="items-list w-100" style="max-height: 300px; overflow-y: scroll; overflow-x:hidden " name="list-complete" tag="div">
              <div v-if="!instruments || !instruments.length" class="mb-4" :key="-1">Список пуст</div>
              <div v-else class="pt-2 pb-2 mb-4" :key="-3">{{ instrumentSet ? 'Доступные инструменты:' : 'Список инструментов'}}</div>
              <div class="row row-complete-item item-question" v-for="(instrument, ind) in instruments" :key="instrument.id">
                <div class="col-9 d-flex align-center">
                  <v-btn v-if="instrumentSet" icon @click="addToSet(instrument)" class="mr-1" :disabled="!canAdd(instrument)">
                    <v-icon class="d-none d-sm-block" color="primary">mdi-arrow-left</v-icon>
                    <v-icon class="d-block d-sm-none" color="primary">mdi-plus</v-icon>
                  </v-btn>
                  <div class="mr-2">{{ ind + 1 }}.</div>
                  <div> {{ instrument.name }} </div>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      <div class="row mt-4 justify-end">
        <v-btn class="mr-3 _w-100" @click="close()"> Закрыть </v-btn>
        <v-btn class="_w-100" color="primary" :disabled="!isChanged" @click="okClicked"> Сохранить </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  components: {
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      statuses: [{ id: 'draft', text: "Черновик" }, { id: 'published', text: "Опубликован" }, { id: 'archived', text: "Архив" }],
      instrumentSet: {
        name: '',
        customer_id: null,
        description: '',
        report_instruction: '',
        status: 'draft',
        instruments: [],
        code: ''
      },
      isChanged: false

    };
  },
  watch: {
    async dialogEditShow(newVal) {
      if (newVal) {
        this.instrumentSet = {
          name: 'Сборка ' + (this.instrumentSets.length + 1),
          customer_id: null,
          description: '',
          report_instruction: '',
          test_results_title: 'Описание',
          test_results_description: `Ниже представлены ключевые направления деятельности, к которым Вы проявили наибольший интерес. Развиваясь в данных направлениях, Вы можете добиться большего успеха, так как данные направления деятельности Вас будут мотивировать в Вашей работе. Для успешного освоения профессий Вам может понадобиться пройти дополнительное обучение, обратитесь за информацией к консультанту или работодателю.`,
          status: 'draft',
          instruments: [],
          code: ''
        }
        this.isChanged = false
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: 'testEditor/loading',
      errors: 'testEditor/errors',
      instruments: 'testEditor/instruments',
      instrumentSets: 'testEditor/instrumentSets',
      clients: 'users/clients/clients',
    }),
    dialogEditShow: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', false);
      }
    },
    codeErrors() {
      const errors = [];
      if (this.instrumentSet.code && !/^[A-Z0-9_\s]+$/u.test(this.instrumentSet.code))
        errors.push("Код должен содержать только символы A-Z, 0-9, _.")
      
      return this.errors?.add?.code ?? errors;
    },
  },
  methods: {
    ...mapActions({
      addInstrumentSet: 'testEditor/addInstrumentSet',
    }),
    ...mapMutations({
      setError: 'testEditor/setError',
    }),
    getInstrumentName(id) {
      const item = this.instruments.find(el => el.id === id)
      return item ? item.name : 'Не найден'
    },
    addToSet(instrument) {
      this.instrumentSet.instruments.push({
        instrument_id: instrument.id,
        order: 0,
        // show_condition": 0        
      })
      this.onChanged()
    },
    canAdd(instrument) {
      return !this.instrumentSet.instruments.find(el => el.instrument_id === instrument.id)
    },
    deleteFromSet(ind) {
      this.instrumentSet.instruments.splice(ind, 1)
      this.onChanged()
    },
    async okClicked() {
      const ret = await this.addInstrumentSet(this.instrumentSet)
      if (ret)
          this.dialogEditShow = false
    },
    close() {
      this.dialogEditShow = false
      this.setError({type: 'add', errors: []})
    },
    onChanged() {
      this.isChanged = true
      this.setError({type: 'add', errors: []})
    },
  }

}
</script>

<style>
.align-lists {
  padding-top: 170px
}

@media screen and (max-width: 600px) {
  .align-lists {
    padding-top: 17px
  }
}
</style>