<template>
  <div class="card">
    <!-- <v-text-field v-model="question.title" label="Заголовок вопроса:" outlined dense hide-details/> -->
    <html-content-editor v-model="question.text" :update="forceUpdate" @input="changed()" title="Текст вопроса:" class="mb-6"/>

    <div class="items-list-wrapper">
      <transition-group class="items-list w-100" name="list-complete" tag="div">
        <div v-if="!choices || !choices.length" class="mb-4" :key="-1">Добавьте ответы</div>
        <div v-else class="mb-4" :key="-3">Ответы:</div>
        <div class="row row-complete-item" v-for="(choice, ind) in choices" :key="choice.id">
          <div class="col-8">
            <v-text-field v-model="choice.text" :label="'Ответ '+(ind+1)" outlined dense hide-details @input="debouncedChanged(choice)"/>
          </div>
          <div class="col-15 pl-0">
            <v-text-field label="Баллы" outlined dense v-model="choice.points" hide-details @input="debouncedChanged(choice)" />
          </div>
          <div class="col-15 pl-0">
            <v-text-field label="Порядок" outlined dense v-model="choice.order" hide-details @input="debouncedChanged(choice)" />
          </div>
          <div class="col-1 pl-2">
            <v-btn icon color="error" @click="askDelete(choice)" :disabled="!$store.getters['auth/isAllowed']('delete_answer')">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="row row-complete-item" :key="-2">
          <v-btn class="add-button" fab dark small color="primary" @click="addChoice({question})" :disabled="!$store.getters['auth/isAllowed']('add_answer')">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </transition-group>
    </div>
    <AskDialog v-model="showAskDeleteDialog" @ok="delChoice(curChoice)" />

  </div>
</template>

<script>
/* eslint-disable */
import { debounce } from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import AskDialog from "@/components/ui/AskDialog"
import HtmlContentEditor from '@/components/testEditor/htmlEditor/HtmlContentEditor.vue';

export default {
  components: {
    HtmlContentEditor, AskDialog
  },
  props: {
    question: Object,
  },
  data() {
    return {
      forceUpdate: false,
      curChoice: 0,
      showAskDeleteDialog: false,
    };
  },
  watch: {
    'question.id'() {
      this.forceUpdate = !this.forceUpdate
    }
  },
  computed: {
    ...mapGetters({
        loading: 'testEditor/loading',
        choices: 'testEditor/choices',
      }),
  },
  methods: {
    ...mapActions({
      saveChoice: 'testEditor/saveChoice',
      addChoice: 'testEditor/addChoice',
      delChoice: 'testEditor/delChoice',
    }),
    askDelete(item) {
      this.curChoice = item
      this.showAskDeleteDialog = true
    },
    onChangeChoice(choice) {
      this.saveChoice(choice)
    },
    debouncedChanged: debounce(function (choice) {
      this.onChangeChoice(choice);
    }, 500),
    changed() {
      
    }
  }

}
</script>

<style>

</style>
