<template>
  <v-dialog v-model="dialogEditShow" max-width="1100px">
    <v-card class="pa-4 pa-sm-8">
      <h4 class="mb-5">{{getQuestionName(question.type)}}</h4>
      <v-btn class="button-close ma-4" icon @click="okClicked">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <QuestionSimpleCard v-if="question.type===Q_TYPES.QUESTON_RADIO" :question="question" />
      <QuestionSimpleCard v-else-if="question.type===Q_TYPES.QUESTON_LIST" :question="question" />
      <QuestionSimpleCard v-else-if="question.type===Q_TYPES.QUESTON_CHECKBOX" :question="question" />

      <QuestionInfoCard v-else-if="question.type===Q_TYPES.TEXT_MESSAGE" :question="question" />
      <QuestionInfoCard v-else-if="question.type===Q_TYPES.QUESTON_TEXT" :question="question" />
      <QuestionInfoCard v-else-if="question.type===Q_TYPES.QUESTON_TEXTAREA" :question="question" />
      <QuestionInfoCard v-else-if="question.type===Q_TYPES.QUESTON_NUMBER" :question="question" />

      <div v-else>Тип вопроса не найден</div>

      <div class="row mt-4 justify-end">
          <v-btn class="mr-3 _w-100" color="primary" @click="okClicked" :loading="!!loading.save"> Закрыть </v-btn>
          <!-- <v-btn class="_w-100" color="primary" @click="okClicked"> Сохранить </v-btn> -->
       </div>
    </v-card>
  </v-dialog>
</template>

<script>
/*eslint-disable*/

import { mapActions, mapState, mapGetters } from 'vuex';
import QuestionSimpleCard from '@/components/testEditor/questionDialog/QuestionSimpleCard.vue';
import QuestionInfoCard from '@/components/testEditor/questionDialog/QuestionInfoCard.vue';
import { getQuestionName, Q_TYPES } from '@/components/testEditor/QuestionTypes.js';

export default {
  components: {
    QuestionSimpleCard, QuestionInfoCard
  },
  model: {
     prop: 'value',
     event: 'input'
  },
  props: {
    value: Boolean,
    question: Object,
  },
  data: () => ({
    Q_TYPES
  }),
  watch: {
    async dialogEditShow(newVal) {
      if (newVal) {
      }
    }
  },
  computed: {
    dialogEditShow: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', false);
      }
    },
    ...mapGetters({
      loading: 'testEditor/loading',
    }),
  },

  methods: {
    ...mapActions({
      saveQuestion: 'testEditor/saveQuestion',
      delQuestion: 'testEditor/delQuestion',
      //loadBlock: 'testEditor/loadBlock',
    }),
    okClicked() {
      // this.$emit('ok');
      this.saveQuestion(this.question)
      this.dialogEditShow = false
    },
    getQuestionName,
  }
};
</script>


